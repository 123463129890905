var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{attrs:{"outlined":"","color":"#ECEFF1"}},[_c('br'),_c('h2',{staticClass:"title-style"},[_c('v-icon',{staticClass:"icon-style"},[_vm._v(" mdi-calendar-clock ")]),_vm._v(" Filter Records ")],1),_c('v-form',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-text',{staticClass:"text-body-1 text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"First Name","hint":"Service provider's first name","persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.filteringData.firstName),callback:function ($$v) {_vm.$set(_vm.filteringData, "firstName", $$v)},expression:"filteringData.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Last Name","hint":"Service provider's last name","persistent-hint":"","outlined":"","dense":""},model:{value:(_vm.filteringData.lastName),callback:function ($$v) {_vm.$set(_vm.filteringData, "lastName", $$v)},expression:"filteringData.lastName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-datetime-picker',{attrs:{"label":"From","date-format":"yyyy-MM-dd","time-format":"HH:mm","text-field-props":{
                  suffix: _vm.userTimeZone,
                  class: 'custom-label-color',
                  outlined: true,
                  dense: true,
                },"date-picker-props":{
                  headerColor: '#37474F',
                  max: _vm.maxStartDate
                },"time-picker-props":{
                  headerColor: '#37474F'
                }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"large":"","color":"#37474F"}},[_vm._v(" mdi-calendar ")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"large":"","color":"#37474F"}},[_vm._v(" mdi-alarm ")])]},proxy:true}]),model:{value:(_vm.filteringData.checkInTime),callback:function ($$v) {_vm.$set(_vm.filteringData, "checkInTime", $$v)},expression:"filteringData.checkInTime"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-datetime-picker',{attrs:{"label":"To","text-field-props":{
                  persistentHint: true,
                  suffix: _vm.userTimeZone,
                  outlined: true,
                  dense: true,
                },"date-picker-props":{
                  headerColor: '#37474F',
                  min: _vm.minEndDate,
                  max: _vm.maxStartDate
                },"time-picker-props":{
                  headerColor: '#37474F'
                }},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',{attrs:{"large":"","color":"#37474F"}},[_vm._v(" mdi-calendar ")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',{attrs:{"large":"","color":"#37474F"}},[_vm._v(" mdi-alarm ")])]},proxy:true}]),model:{value:(_vm.filteringData.checkOutTime),callback:function ($$v) {_vm.$set(_vm.filteringData, "checkOutTime", $$v)},expression:"filteringData.checkOutTime"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.possibleTypes,"item-text":"name","item-value":"id","label":"Type","outlined":"","dense":""},model:{value:(_vm.filteringData.type),callback:function ($$v) {_vm.$set(_vm.filteringData, "type", $$v)},expression:"filteringData.type"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"red darken-3","depressed":"","default":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"#FF3700","depressed":"","default":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Filter ")])],1)],1)],1)],1),_c('centre-spinner',{attrs:{"loading":_vm.loading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }