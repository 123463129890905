<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-clipboard-check-multiple
      </v-icon> Check-In Records
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <div
        class="header"
      >
        <div class="div-2">
          <v-btn
            color="#FF3700"
            @click="showFilterDialog = true"
          >
            Filter
          </v-btn>
        </div>
        <div class="div-1">
          <download-excel
            :data="checkInRecords"
            :fields="jsonFields"
            worksheet="SwiftCheckinRecords"
            :name="getExportSheetName"
            :button_text="btnName"
          >
            <v-btn color="#37474F">
              Export
            </v-btn>
          </download-excel>
        </div>
        <div class="div-3">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                dark
                outlined
                color="#37474F"
                small
                v-on="on"
                @click="fetchCheckInRecords()"
              >
                <v-icon
                  small
                  dark
                >
                  mdi-file-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <span>ALL Records</span>
          </v-tooltip>
        </div>
      </div>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="checkInRecords"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.service_provider.first_name }} {{ item.service_provider.last_name }}</td>
            <td>{{ item.service_provider.company.company_name }}</td>
            <td>
              <span v-if="item.service_provider.contractor_category !== null">
                {{ item.service_provider.contractor_category.name }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>
              <span v-if="item.job_number !== null">
                {{ item.job_number }}
              </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.site.name }}</td>
            <td>{{ item.checkin_time | convertToLocal }}</td>
            <td>{{ item.type }}</td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Active*
              </font><font v-else>
                {{ item.checkout_time | convertToLocal }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="recordDetails(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <records-filter-dialog
      v-if="showFilterDialog"
      @closed="showFilterDialog = false"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import RecordsFilterDialog from './RecordsFilterDialog.vue';
  import JsonExcel from 'vue-json-excel';

  export default {
    name: 'CheckInRecords',
    components: {
      'centre-spinner': spinner,
      'records-filter-dialog': RecordsFilterDialog,
      'download-excel': JsonExcel,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },
    data () {
      return {
        search: '',
        headers: [
          { text: 'Name', align: 'start', value: 'service_provider.first_name' },
          { text: 'Company', value: 'company' },
          { text: 'Category', value: 'category' },
          { text: 'Job Number', value: 'job_number' },
          { text: 'Site', value: 'name' },
          { text: 'Check-In At', value: 'check_in_time' },
          { text: 'Check-In Type', value: 'type' },
          { text: 'Check-Out At', value: 'country' },
          { text: 'More', value: 'more' },
        ],
        loading: false,
        showFilterDialog: false,
        btnName: 'Export',
        jsonFields: {
        'First Name': 'service_provider.first_name',
        'Last Name': 'service_provider.last_name',
        Company: 'service_provider.company.company_name',
        'Job Number': 'job_number',
        Site: 'site.name',
        Type: 'type',
        'Check-In Time': {
          field: 'checkin_time',
          callback: (value) => {
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Check-Out Time': {
          field: 'checkout_time',
          callback: (value) => {
            if (value === null || value === '') {
              return '-';
            }
            return moment(value).local().format('Do MMMM YYYY hh:mm A');
          },
        },
        'Onsite Hours': 'total_hours',
      },
      };
    },
    computed: {
      checkInRecords () {
        const list = this.$store.getters['records/getCheckInRecords'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
      getExportSheetName () {
        return 'swift_checkin_records_' + moment().format('DD-MM-YYYY-hh-mm') + '.xls';
      },
    },
    async mounted () {
      this.loading = true;
      await this.fetchCheckInRecords();
      this.loading = false;
    },
    methods: {
      async fetchCheckInRecords () {
        this.loading = true;
        await this.$store.dispatch('records/fetchCheckInRecords');
        this.loading = false;
      },
      recordDetails (recordId) {
        this.$router.push(`records/${recordId}`);
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.records-btn {
  margin-top: 10px;
}
.header {
  display: inline-block;
  width: 100%;
}
.div-1 {
  margin-top: 15px;
  float: right;
  margin-bottom: 10px;
}
.div-2 {
  margin-top: 15px;
  float: right;
}
.div-3 {
  margin-top: 15px;
  float: right;
  margin-right: 15px;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
</style>
